* {
	font-family: 'Montserrat', sans-serif;
}
.ant-form-item-control {
	line-height: 2px;
}
.ant-form-item-label {
	line-height: 25px;
}

.ant-carousel .slick-slide {
  text-align: center;
  background: #fff;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
svg { max-height: 100%; }
